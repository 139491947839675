<template>
  <v-form :ref="reference" v-model="valid">
    <div class="form-container">
      <div class="form-tab">{{ formTitle }}</div>
      <v-row :class="{ 'boxed': boxed }" class="pt-4">
        <slot name="fields"></slot>
      </v-row>
    </div>
  </v-form>
</template>

<script>
export default {
  name: 'FormWrapper',
  props: {
    formTitle: {
      type: String,
      default: 'Form Details'
    },
    reference: {
      type: String,
      required: true
    },
    boxed: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    valid: true
  }),
  watch: {
    valid(newValue) {
      this.$emit('update:valid', newValue);
    }
  },
  mounted() {
    const isValid = this.$refs[this.reference].validate();
    this.$emit('update:valid', isValid);

    this.$root.$on('reset:form', () => {
      console.log('reset:form')
      this.$refs[this.reference].reset();
      this.$refs[this.reference].resetValidation();
    })
    this.$root.$on('validate:form', () => {
      console.log('validate:form')
      this.$refs[this.reference].validate();
    })
  },
  destroyed() {
    this.$root.$off('reset:form');
    this.$root.$off('validate:form');
  }
}
</script>

<style scoped>
.form-container {
  position: relative;
}

.form-tab {
  position: absolute;
  top: -1em;
  left: 15px;
  background-color: white;
  padding: 0 10px;
  margin: 2px 0;
  border: 1px solid #d5d5d5;
  border-radius: .2rem;
  color: #333;
  font-weight: bold;
}

.boxed {
  border: 1px dashed #d5d5d5;
  border-radius: 5px;
  background: rgb(253, 253, 253);
}
</style>
